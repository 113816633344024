import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';

import {
  Link, useLocation, useNavigate, useOutletContext,
} from 'react-router-dom';
import {
  Button, Col, Form, FormControl, FormLabel, Row, Spinner,
} from 'react-bootstrap';

import StatusAlert from '../components/StatusAlert';
import useAuth from '../hooks/useAuth';
import useForm from '../hooks/useForm';

import './auth.css';

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : '/console';
}

function Login() {
  const { t } = useTranslation();

  const title = t('login-title');
  const context = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const { login, getSession } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [user, setUser] = useState(getSession())

  useEffect(() => {
    if (user) {
      navigate(redirectPath(search));
    }
  }, [])

  const handleLogin = async (e, data) => {
    // eslint-disable-next-line no-console
    try {
      setIsLoading(true);
      const token = await login(data.email, data.password);
      // eslint-disable-next-line no-console
      console.log(`login successful, token: ${token}`);
      setIsLoading(false);
      navigate(redirectPath(search));
    } catch (err) {
      console.log('err', err)
      context.setAlertOpts({
        text: err.response?.data?.message ?? err.message,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  const validators = {
    email: {
      required: {
        value: true,
        message: 'Email is required',
      },
    },
    password: {
      required: {
        value: true,
        message: 'Password is required',
      },
    },
  };

  // Using a custom hook to show how we can build out our own hook.
  const {
    data, handleChange, handleSubmit, errors,
  } = useForm({
    onSubmit: handleLogin,
    validators,
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="text-center mt-5 p-5 ">
        <div className="row justify-content-center " style={ { minHeight: '70vh' } }>
          <div className="col col-md-5 text-start p-0 p-md-5 signin-welcome ">
            <h1>Welcome</h1>
            <p className='fs-5 text-shadows'>Big Boss Generation</p>
          </div>
          <div className="col col-md-3 col-12 pt-md-2 pt-sm-0 pb-3 my-0 bg-tr-dark rounded-3 fade-in">
            <h1 className='text-chaman my-3 text-start'>{t('login-button')}</h1>
            <Form noValidate>
              <Form.Group className="form-floating" controlId="inputEmail">
                <FormControl type="text"
                  className="form-control form-input-top mb-3"
                  isInvalid={errors?.email}
                  placeholder={t('login-email')}
                  onChange={handleChange('email')}
                />
                <FormLabel>{t('login-email')}</FormLabel>
              </Form.Group>
              <Form.Group className="form-floating" controlId="inputPassword">
                <FormControl type="password"
                  className="form-control form-input-bottom"
                  isInvalid={errors?.password}
                  placeholder={t('login-password')}
                  onChange={handleChange('password')}
                />
                <FormLabel>{t('login-password')}</FormLabel>
              </Form.Group>
              <div>
                {Object.keys(errors).map((key) => <div className="text-danger" key={key}>{errors[key]}</div>)}
              </div>
              <Form.Group as={Row} className="my-3" controlId="isRemember">
                <Col sm={{ span: 8, offset: 3 }} className="text-md-start">
                  <Form.Check label="Remember me"
                    checked={data.isRemember}
                    onChange={handleChange('isRemember')} />
                </Col>
              </Form.Group>
              <div className="row mb-3">
                <div className="col-6"><Link to="/forgot">{t('login-forgot-password')}</Link></div>
                <div className="col-6"><Link to="/signup">{t('login-new-account')}</Link></div>
              </div>
              <Button className="w-100 btn btn-lg btn-dark"
                type="button"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                <span className="px-2">{t('login-button')}</span>
              </Button>
            </Form>
          </div>
        </div>

      </main>
    </>
  );
}

export default Login;
