import axios from 'axios';
import * as config from '../config'

const keyUser = 'authx.user';
const registeredUsers = new Map([
  ['admin', {
    id: 'uid:0', username: 'admin', email: 'admin@example.com', password: 'qwerty', firstname: 'App', lastname: 'Admin',
  }],
  ['lee', {
    id: 'uid:973236115', username: 'Fat', email: 'fat@choi.com', password: '12345', firstname: 'Fat', lastname: 'Chow',
  }],
]);

function newUID() {
  const epoch = Math.floor(new Date() / 1000).toString();
  return `uid:${epoch}`;
}

function newToken() {
  return (Math.random() * 1000000000).toString(16);
}

function setSession(user, token) {
  // Remove the password property.
  const { password, ...rest } = user;

  // Merge token to the final object.
  const merged = {
    ...rest,
    token,
  };

  localStorage.setItem(keyUser, JSON.stringify(merged));
}

function getSession() {
  const user = localStorage.getItem(keyUser);

  return JSON.parse(user);
}

function isAuth() {
  return !!getSession();
}

async function login(email, password) {
  return axios.post(`${config.API_URL}/auth/login`, { email, password }).then((response) => {
    if (response?.data?.customer) {
      const token = newToken();
      setSession(response?.data?.customer, token);
      return token
    }
    return null
  })
}

async function kycToken() {
  const response = await axios.get(`${config.API_URL}/auth/kycToken`, {})
  console.log('response?.data?.accessToken', response?.data?.data?.accessToken)
  if (response?.data?.data?.accessToken) {
    return response?.data?.data?.accessToken
  }

  return null
}

const syncKYC = async (customerId) => {
  try {
    const response = await fetch(`${config.API_URL}/auth/syncKYC/${customerId}`);
    const customer = await response.json();
    console.log('Sync KYC Response:', customer);
    return customer;
  } catch (error) {
    console.error('Error fetching transactions:', error);
  }
  return null;
};

async function logout() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      localStorage.removeItem(keyUser);
      resolve();
    }, 1000);
  });
}

async function sendPasswordReset() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}

async function addUser(user) {
  try {
    const response = await axios.post(`${config.API_URL}/auth/register`, user);
    if (!response?.data?.customer?.id) {
      throw response
    }
    return response.data; // Return the response data (e.g., customer details)
  } catch (error) {
    throw error.response ? error.response.data : new Error('Registration failed');
  }
}

async function getUsers() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      const users = Array.from(registeredUsers.values());
      resolve(users);
    }, 1000);
  });
}

// The useAuth hook is a wrapper to this service, make sure exported functions are also reflected
// in the useAuth hook.
export {
  getSession, isAuth, login, logout, sendPasswordReset, addUser, getUsers, kycToken, syncKYC,
};
