import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import './btn.css';

function Blockchain() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('blockchain-title')}</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-0 px-md-4 pt-4  pt-md-4 mt-5 row">
          <div className='col-md-12 col-sm-12 text-left p-md-5 p-sm-1'>
            <h1 className="display-5 fw-bold py-4 text-chaman">{t('blockchain-title')}</h1>
            <h2 className="fw-bold py-4  text-chaman">{t('membership-utility')}</h2>
            <div className="row d-flex" id="bc-table">
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">

                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header">
                    <h3 className="card-title text-center text-bronze text-chaman"
                      style={{ fontSize: (window.innerWidth >= 640) ? '24px' : '13px' }}
                    >&nbsp;{i18n.language === 'zh' && <span><br />&nbsp;</span>}</h3>
                  </div>
                  <ul className="list-group list-group-flush text-end">
                    <li className="list-group-item">{t('mu-holding-token')}<span className="d-none d-md-inline"> {t('mu-in-wallet')}</span></li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">{t('mu-application-required')}</span>
                      <span className="d-inline d-md-none">{t('mu-application-required-short')}</span>
                    </li>
                    <li className="list-group-item">{t('mu-referral')}</li>
                    <li className="list-group-item">{t('mu-quota')}</li>

                    <li className="list-group-item fw-bold pt-4" style={{ minHeight: '68px', marginTop: '48px' }}>{t('mu-benefits')}</li>
                    <li className="list-group-item d-none d-md-block" style={{ minHeight: '80px' }}><Trans i18nKey='mu-vsop' /></li>
                    <li className="list-group-item d-md-none" style={{ minHeight: '80px', fontSize: '12px' }}><Trans i18nKey='mu-vsop' /></li>
                    <li className="list-group-item">{t('mu-priority')}<span className="d-none d-md-inline">{t('mu-entry')}</span></li>
                    <li className="list-group-item"><span className="d-none d-md-inline">{t('mu-includes')}</span>{t('mu-guest')}</li>
                    <li className="list-group-item">{t('mu-private-lounge')}<span className="d-none d-md-inline">{t('mu-access')}</span><sup>1</sup></li>
                    <li className="list-group-item">{t('mu-private-lounge')}<span className="d-none d-md-inline">{t('mu-access')}</span><sup>2</sup></li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">{t('mu-private-events-BBC')}</span>
                      <span className="d-md-none d-inline">{t('mu-private-events-BBC-short')}</span>
                    </li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">{t('mu-special-event')}{t('mu-invitations')}</span>
                      <span className="d-md-none d-inline">{t('mu-special-event')}</span>
                    </li>
                    <li className="list-group-item">
                      <span className="d-none d-md-inline">{t('mu-personalized')}{t('mu-concierge')}</span>
                      <span className="d-md-none d-inline">{t('mu-personalized')}<br />{t('mu-concierge')}</span>
                    </li>
                  </ul>
                  <div className='card-footer'>
                  </div>
                </div>

              </div>
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header bg-dark">
                    <h3 className="card-title text-center text-bronze text-chaman text-wrap"
                      style={{ fontSize: (window.innerWidth >= 640) ? '24px' : '13px' }}
                    >
                      <Trans i18nKey={'mu-BRONZE'} />
                    </h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$50,000</li>
                    <li className="list-group-item border-bottom">{t('mu-yes')}</li>
                    <li className="list-group-item border-bottom">{t('mu-no')}</li>
                    <li className="list-group-item border-bottom">{t('mu-UNLIMITED')}</li>

                    <li className="list-group-item border-bottom  pt-4" style={{ minHeight: '68px', marginTop: '48px' }}>{t('mu-Elite')}</li>
                    <li className="list-group-item border-bottom pt-4" style={{ minHeight: '80px' }}>3 {t('mu-bottle')}</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item "><i className="bi bi-x-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-bronze my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > {t('mu-buy')} </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex ps-1 pe-0 pe-md-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header bg-dark">
                    <h3 className="card-title text-center text-silver text-chaman text-wrap"
                      style={{ fontSize: (window.innerWidth >= 640) ? '24px' : '13px' }}
                    >
                      <Trans i18nKey={'mu-SILVER'} />
                    </h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$200,000</li>
                    <li className="list-group-item border-bottom">{t('mu-yes')}</li>
                    <li className="list-group-item border-bottom">{t('mu-yes')}</li>
                    <li className="list-group-item border-bottom">{t('mu-UNLIMITED')}</li>

                    <li className="list-group-item border-bottom  pt-4" style={{ minHeight: '68px', marginTop: '48px' }}>{t('mu-Prestige')}</li>
                    <li className="list-group-item border-bottom pt-4" style={{ minHeight: '80px' }}>15 {t('mu-bottle')}</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-x-lg"></i></li>
                    <li className="list-group-item "><i className="bi bi-x-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-silver my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > {t('mu-buy')} </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex px-1">
                <div className="card bg-tr-dark h-100 w-100 rounded-3">
                  <div className="card-header bg-dark px-2">
                    <h3 className="card-title text-center text-gold text-chaman text-wrap px-0"
                      style={{ fontSize: (window.innerWidth >= 640) ? '24px' : '13px' }}
                    >
                      <Trans i18nKey={'mu-GOLD'} />
                    </h3>
                  </div>
                  <ul className="list-group list-group-flush text-center px-1 px-md-4">
                    <li className="list-group-item border-bottom">HK$500,000</li>
                    <li className="list-group-item border-bottom">{t('mu-yes')}</li>
                    <li className="list-group-item border-bottom">{t('mu-yes')}</li>
                    <li className="list-group-item border-bottom">{t('mu-LIMITED')} 100</li>

                    <li className="list-group-item border-bottom pt-4" style={{ minHeight: '68px', marginTop: '48px' }}>{t('mu-Infinity')}</li>
                    <li className="list-group-item border-bottom pt-4" style={{ minHeight: '80px' }}>50 {t('mu-bottle')}</li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item border-bottom"><i className="bi bi-check-lg"></i></li>
                    <li className="list-group-item"><i className="bi bi-check-lg"></i></li>
                  </ul>
                  <div className='card-footer'>
                    <p className="text-center">
                      <button className='button-gold my-2 px-0 py-2 rounded-pill w-100 fs-6 fs-md-4'
                        onClick={() => navigate('/buy')}
                      > {t('mu-buy')} </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 px-md-5 py-2 my-0 row">
          <div className='mx-md-3 mx-sm-0 col-12 text-justify px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>
            <h1 className="display-5 fw-bold py-4 text-chaman text-center text-md-start">{t('blockchain-regristration')} </h1>
            <p className="lead mb-4 fs-5 text-center text-md-start text-white pr-5" style={{ backgroundColor: '#0000' }}>
              {t('blockchain-reg-text')}
              <br />
              <br />
              <button className='button-gold my-3 px-5 py-2 rounded-pill'
                onClick={() => navigate('/signup')}
              > {t('register')} </button>
            </p>
          </div>
        </div>
        <div className="w-100" style={{ height: '498px', backgroundColor: '#0000' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default Blockchain;
